import { BreadcrumbGroupProps, StatusIndicator, TableProps } from '@amzn/awsui-components-react';
import { DynamicColumnDetails } from 'src/models/XptTableModel';
import { UserAccessEntity } from 'src/models/XptUsersModel';
import { convertToLocalReadableFormat } from 'src/utils/date-time-utilities';

export const getAccessAuthBreadCrumbs = (
  businessGroupBaseBreadcrumbs: BreadcrumbGroupProps.Item[],
  currentBusinessGroupName?: string
): BreadcrumbGroupProps.Item[] => {
  if (!currentBusinessGroupName) {
    return businessGroupBaseBreadcrumbs;
  }

  return [
    ...businessGroupBaseBreadcrumbs,
    {
      text: 'Access & Authorization',
      href: `/${currentBusinessGroupName}/access-authorization`
    }
  ];
};

export const UserAccessTableConfig: DynamicColumnDetails = {
  columnInfo: {
    entityName: 'Access & Authorization',
    trackBy: 'access_id',
    sortingColumn: 'updated_at',
    isDescending: true
  },
  data: [
    {
      fieldName: 'access_id',
      displayName: 'Access Id',
      canBeHiddenFromProperties: true,
      isShownByDefault: false,
      isFilterable: false,
      metadata: {
        type: 'number'
      }
    },
    {
      fieldName: 'data_classification_id',
      displayName: 'Classification Id',
      canBeHiddenFromProperties: true,
      isShownByDefault: false,
      isFilterable: false,
      metadata: {
        type: 'number'
      }
    },
    {
      fieldName: 'user_alias',
      displayName: 'Alias',
      canBeHiddenFromProperties: false,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'string'
      }
    },
    {
      fieldName: 'user_name',
      displayName: 'Name',
      canBeHiddenFromProperties: false,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'string'
      }
    },
    {
      fieldName: 'user_type',
      displayName: 'User Type',
      canBeHiddenFromProperties: false,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'string'
      }
    },
    {
      fieldName: 'is_active',
      displayName: 'Active',
      canBeHiddenFromProperties: true,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'boolean'
      }
    },
    {
      fieldName: 'created_at',
      displayName: 'Created At',
      canBeHiddenFromProperties: true,
      isShownByDefault: false,
      isFilterable: false,
      metadata: {
        type: 'dateTime'
      }
    },
    {
      fieldName: 'created_by',
      displayName: 'Created By',
      canBeHiddenFromProperties: true,
      isShownByDefault: false,
      isFilterable: false,
      metadata: {
        type: 'string'
      }
    },
    {
      fieldName: 'updated_at',
      displayName: 'Updated At',
      canBeHiddenFromProperties: true,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'dateTime'
      }
    },
    {
      fieldName: 'updated_by',
      displayName: 'Updated By',
      canBeHiddenFromProperties: true,
      isShownByDefault: true,
      isFilterable: true,
      metadata: {
        type: 'string'
      }
    }
  ]
};

export const getUserAccessTableDefinitions = (): TableProps.ColumnDefinition<UserAccessEntity>[] => [
  {
    id: 'access_id',
    header: 'Access Id',
    cell: (item: UserAccessEntity) => item.access_id,
    sortingField: 'access_id'
  },
  {
    id: 'data_classification_id',
    header: 'Classification Id',
    cell: (item: UserAccessEntity) => item.data_classification_id,
    sortingField: 'data_classification_id'
  },
  {
    id: 'user_alias',
    header: 'Alias',
    cell: (item: UserAccessEntity) => item.user_alias,
    sortingField: 'user_alias'
  },
  {
    id: 'user_name',
    header: 'Name',
    cell: (item: UserAccessEntity) => item.user_name,
    sortingField: 'user_name'
  },
  {
    id: 'user_type',
    header: 'User Type',
    cell: (item: UserAccessEntity) => item.user_type,
    sortingField: 'user_type'
  },
  {
    id: 'is_active',
    header: 'Active',
    cell: (item: UserAccessEntity) => (
      <StatusIndicator colorOverride={item.is_active ? 'green' : 'red'} type={item.is_active ? 'success' : 'stopped'}>
        {item.is_active ? 'Active' : 'Inactive'}
      </StatusIndicator>
    ),
    sortingField: 'is_active'
  },
  {
    id: 'created_at',
    header: 'Created At',
    cell: (item: UserAccessEntity) => convertToLocalReadableFormat(item.created_at),
    sortingField: 'created_at'
  },
  {
    id: 'created_by',
    header: 'Created By',
    cell: (item: UserAccessEntity) => item.created_by,
    sortingField: 'created_by'
  },
  {
    id: 'updated_at',
    header: 'Updated At',
    cell: (item: UserAccessEntity) => convertToLocalReadableFormat(item.updated_at),
    sortingField: 'updated_at'
  },
  {
    id: 'updated_by',
    header: 'Updated By',
    cell: (item: UserAccessEntity) => item.updated_by,
    sortingField: 'updated_by'
  }
];
