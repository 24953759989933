import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUserAccessAuthorization } from 'src/api/app-sync-services';
import { LoadingStatus } from 'src/models/AuthContextModels';
import { UserAccessEntity, UserAuthorization } from 'src/models/XptUsersModel';
import { getCurrentUTCTimeInISO } from 'src/utils/date-time-utilities';

interface AccessAndAuthorizationState {
  users: UserAuthorization;
  errorMessage: string | null;
}

const initialState: AccessAndAuthorizationState = {
  users: {
    users_access_and_authorization_status: LoadingStatus.NotInitiated,
    last_loaded_at: '',
    users_access: []
  },
  errorMessage: null
};

/**
 * Thunk for fetching user access and authorization data.
 */
export const fetchUserAccessAndAuthorization = createAsyncThunk<UserAuthorization, void, { rejectValue: string }>(
  'accessAndAuthorization/fetchUserAccessAndAuthorization',
  async (_, { rejectWithValue }) => {
    try {
      const userAccessData: UserAccessEntity[] = await getUserAccessAuthorization();
      return {
        users_access_and_authorization_status: LoadingStatus.Completed,
        last_loaded_at: getCurrentUTCTimeInISO(),
        users_access: userAccessData
      };
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Unknown error occurred';
      return rejectWithValue(`Failed to fetch user access and authorization data: ${errorMessage}`);
    }
  }
);

const accessAndAuthorizationSlice = createSlice({
  name: 'accessAndAuthorization',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserAccessAndAuthorization.pending, (state) => {
        state.users.users_access_and_authorization_status = LoadingStatus.Loading;
        state.errorMessage = null;
      })
      .addCase(fetchUserAccessAndAuthorization.fulfilled, (state, action: PayloadAction<UserAuthorization>) => {
        state.users = action.payload;
        state.errorMessage = null;
      })
      .addCase(fetchUserAccessAndAuthorization.rejected, (state, action) => {
        state.users.users_access_and_authorization_status = LoadingStatus.Failed;
        state.users.users_access = [];
        state.users.last_loaded_at = '';
        state.errorMessage = action.payload ?? 'Failed to fetch user access and authorization data';
      });
  }
});

export default accessAndAuthorizationSlice.reducer;
