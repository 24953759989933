import { Box, ExpandableSection, HelpPanel, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';

export const AccessAuthorizationInfoPanel: React.FC = () => {
  return (
    <HelpPanel header={<h2>Access & Authorization Info</h2>}>
      <SpaceBetween size="l" direction="vertical">
        <Box variant="p">
          This page is accessible only to users with the <strong>Finance Admin</strong> or <strong>Finance Owner</strong> roles.
        </Box>

        {/* User Types Section */}
        <ExpandableSection headerText="User Roles and Permissions">
          <SpaceBetween size="s" direction="vertical">
            <Box variant="p">Below are the different user types and the corresponding permissions they have within the system:</Box>
            <ul>
              <li>
                <Box variant="h5">Finance Admin</Box>
                <Box variant="p">
                  Can manage planning cycles, update project mapping, perform CapEx file uploads, and manage access & authorization settings. Finance
                  Admins have the most extensive permissions and can also perform all operations available to other user types.
                </Box>
              </li>
              <li>
                <Box variant="h5">Finance Owner</Box>
                <Box variant="p">
                  Can manage budget leaders and owners, configure expense types and useful life mappings, create snapshots, set deadlines, and handle
                  access & authorization.
                </Box>
              </li>
              <li>
                <Box variant="h5">Budget Leader</Box>
                <Box variant="p">
                  Can add or edit line items on behalf of budget owners. They play a coordinating role between Finance Owners and Budget Owners.
                </Box>
              </li>
              <li>
                <Box variant="h5">Budget Owner</Box>
                <Box variant="p">
                  Can view, add, and edit their own line items. Budget Owners are responsible for maintaining the accuracy of their budget inputs.
                </Box>
              </li>
            </ul>
          </SpaceBetween>
        </ExpandableSection>

        {/* Guidelines Section */}
        <ExpandableSection headerText="Management Guidelines">
          <SpaceBetween size="s" direction="vertical">
            <Box variant="p">Guidelines for managing user roles within the system:</Box>
            <ul>
              <li>
                <Box variant="p">
                  Only developers are authorized to add a <strong>Finance Admin</strong>.
                </Box>
              </li>
              <li>
                <Box variant="p">
                  <strong>Finance Admins</strong> can add or modify other Finance Admins, as well as manage other user roles (Finance Owners, Budget
                  Leaders, Budget Owners).
                </Box>
              </li>
              <li>
                <Box variant="p">
                  <strong>Finance Owners</strong> can add or edit other Finance Owners, Budget Leaders, and Budget Owners. However, they cannot add or
                  modify Finance Admins.
                </Box>
              </li>
            </ul>
          </SpaceBetween>
        </ExpandableSection>
      </SpaceBetween>
    </HelpPanel>
  );
};
