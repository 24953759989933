// This is used for Loading Status
export enum LoadingStatus {
  NotInitiated = 'notInitiated',
  Validating = 'validating',
  Loading = 'loading',
  Completed = 'completed',
  Failed = 'failed',
  Expired = 'expired'
}

export interface UserAuthContext {
  Alias: string;
  DisplayName: string;
  GivenName: string;
  Email: string;
  userLDAPGroups: string[];
  userAuthDataLoadingStatus: LoadingStatus;

  isUserRoleModified: boolean;
  modifiedRoleAs: string;

  initiallyDev: boolean;
  isDev: boolean;             // Has Developer Access
  isAdmin: boolean;           // Has Admin Access
  isBusinessLeader: boolean;  // Has Business Leader Access
  isBudgetOwner: boolean;     // Has Budget Owner Access
  isReadOnlyUser: boolean;    // Has Read Only Access
  noAccess: boolean;
  isNewUser: boolean;         
}

export interface SecretKeyConfig {
  environment: string;
  secretARN: string;
}
