import { SideNavigation, SideNavigationProps } from '@amzn/awsui-components-react';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { findActiveHref } from 'src/utils/navigation-utilities';
import { useAuth } from '../auth/AuthContextProvider';

const BusinessGroupSideNavigation: React.FC = () => {
  const { isDev, isAdmin, isBusinessLeader, isBudgetOwner, isReadOnlyUser } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { businessGroupName } = useParams<{ businessGroupName: string }>();
  const isConsideredAsAdmin = isDev || isAdmin || isBusinessLeader;

  // This should be accessible to isConsideredAsAdmin
  const businessLeaderSideNavigationItems: SideNavigationProps.Item[] = [
    {
      type: 'section',
      text: 'Finance Owner Tools',
      items: [
        { type: 'link', text: 'Access & Authorization', href: `/${businessGroupName}/access-authorization` },
        { type: 'link', text: 'Planning Cycle', href: `/${businessGroupName}/planning-cycle` },
        { type: 'link', text: 'Snapshots', href: `/${businessGroupName}/snapshots` }
      ]
    }
  ];

  // This should be accessible to isConsideredAsAdmin or isBudgetOwner
  const businessOwnerSideNavigationItems: SideNavigationProps.Item[] = [
    {
      type: 'section',
      text: 'Planning',
      items: [
        { type: 'link', text: 'Forecast Input', href: `/${businessGroupName}/forecast-input` },
        { type: 'link', text: 'PO Tagging', href: `/${businessGroupName}/po-tagging` },
      ]
    },
    {
      type: 'section',
      text: 'Reports',
      items: [
        {
          type: 'link',
          text: 'Forecast',
          href: `/${businessGroupName}/forecast-report`
        },
        {
          type: 'link',
          text: 'Variance',
          href: `/${businessGroupName}/variance-report`
        }
      ]
    }
  ];

  // If !isConsideredAsAdmin && !isBudgetOwner then don't show anything
  const sideNavigationItems = [
    ...(isConsideredAsAdmin ? businessLeaderSideNavigationItems : []),
    ...(isConsideredAsAdmin || isBudgetOwner || isReadOnlyUser ? businessOwnerSideNavigationItems : [])
  ];

  const activeHref = findActiveHref(pathname, sideNavigationItems);

  // If no items should be displayed, return null
  if (sideNavigationItems.length === 0) {
    return null;
  }

  return (
    <SideNavigation
      activeHref={activeHref}
      header={{
        href: `/${businessGroupName}/forecast-input`,
        text: `${businessGroupName}`
      }}
      onFollow={(event) => {
        if (!event.detail.external) {
          event.preventDefault();
          navigate(event.detail.href);
        }
      }}
      items={sideNavigationItems}
    />
  );
};

export default BusinessGroupSideNavigation;
