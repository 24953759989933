import React from 'react';
import { Link } from '@amzn/awsui-components-react';
import { PHONE_TOOL_URL } from 'src/constants/generic-constants';
import UserImageIcon from './UserImageIcon';

interface XptUserAliasNameWithImgIconProps {
  userAlias: string;
  userName?: string;
  width?: string; // Add width as an optional prop
  imgSize?: number;
}

/**
 * XptUserAliasNameWithImgIcon component displays a user alias with an image icon
 * and a link to the user's profile in the phone tool.
 */
export const XptUserAliasNameWithImgIcon: React.FC<XptUserAliasNameWithImgIconProps> = ({ 
  userAlias, 
  userName, 
  width = '10rem', // Default width is 10rem if not passed
  imgSize = 20
}) => { 
  const userPhoneToolLink = `${PHONE_TOOL_URL}${userAlias}`;
  
  return (
    <div className="user-alias-img" style={{ width }}> {/* Apply width dynamically */}
      <UserImageIcon alias={userAlias} size={imgSize} />
      <Link target="_blank" variant="secondary" href={userPhoneToolLink}>
        {userName ? userName : userAlias}
      </Link>
    </div>
  );
};

export default XptUserAliasNameWithImgIcon;
