export const TRANSLATIONS_DIR_NAMESPACE = 'translations';

// This route is determined by translationOutputDir in configUtils
export const TRANSLATION_ROUTE = `/${TRANSLATIONS_DIR_NAMESPACE}`;

export const DEFAULT_LOCALE_EN_US = 'en-US';

export const APP_NAME = 'Expense Planning Tool';
export const APP_NAME_SHORT = 'xPT';
export const APP_DESCRIPTION = {
  intro: `Expense Planning Tool (xPT) is DaS Finance's new Non-HC OPEX Planning Tool. Serving as a centralized platform, xPT automates the entire end-to-end planning process, from collecting inputs from budget owners to facilitating reviews and providing consolidated roll-up views with detailed commentary for quick analysis.`,
  integrationAndUse: `Seamlessly integrated with Prophecy for automated budget loading, xPT empowers DaS Finance users with enhanced control over planning, reporting, security management, and calendaring functionalities.`,
  features: `It is utilized across Software, Hardware, Operations, and Device Managed Marketing spend, xPT consolidates forecast data into a singular view, delivering invaluable insights for informed decision-making. Additional features include scenario analysis, real-time comparisons of actuals versus forecasts, integration with PO data, and customizable viewing options, ensuring a tailored experience for every user.`
};

export enum eCacheKeys {
  UserAuthDetails = '-userAuthDetails'
}

export enum STAGES {
  DEV = 'dev',
  ALPHA = 'alpha',
  BETA = 'beta',
  PROD = 'prod'
}

export const PHONE_TOOL_URL = 'https://phonetool.amazon.com/users/';
export const USER_IMG_BASE_URL = 'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=';

export const eLDAPGroup = {
  DEV_LDAP: 'expense-planning-tool-dev',
  ADMIN_LDAP: 'ad3-team',
  expense_planning_tool_ro: 'expense-planning-tool-ro',
  expense_planning_tool_fpna_admin: 'expense-planning-tool-fpna-admin'
};

// User types as individual constants
export const FINANCE_ADMIN = 'Finance Admin';
export const FINANCE_OWNER = 'Finance Owner';
export const BUDGET_LEADER = 'Budget Leader';
export const BUDGET_OWNER = 'Budget Owner';

// User types array for easy iteration or selection
export const USER_TYPES = [
  FINANCE_ADMIN,
  FINANCE_OWNER,
  BUDGET_LEADER,
  BUDGET_OWNER,
] as const;

export enum eTicketLinks {
  TICKETS_TOOL = 'https://t.corp.amazon.com/',
  GENERAL_ISSUE_TEMPLATE_URL = 'https://t.corp.amazon.com/create/templates/cbda2d4a-511c-48c2-8e23-81e1c6242e76'
}

export enum eSecretManagerKeys {
  XPT_DEV_API_KEY = 'xpt-appsync-apikey',
  XPT_APPSYNC_API = 'xpt-appsync-endpoint',
  AD3_PAPI_API = 'xpt-PAPI'
}

export enum eLocalStorageKeys {
  IsDarkModeEnabled = 'isDarkModeEnabled',
  UserAuthDetails = 'userAuthDetails',
  ForecastTemplateDetailsSelection = 'forecastTemplateDetailsSelection',
  XptHomePageWidgetsLayout = 'xptHomePageWidgetsLayout'
}

export enum eEntityStatus {
  Error = 'error',
  Warning = 'warning',
  Success = 'success',
  Stopped = 'stopped',
  Info = 'info',
  Pending = 'pending',
  InProgress = 'in-progress',
  Loading = 'loading',

  // For Scenario Status
  Open = 'Open',
  Closed = 'Closed',
  Locked = 'Locked',

  // generic purpose
  Active = 'Active',
  Inactive = 'Inactive',

  // scenario snapshot status
  Scheduled = 'scheduled'
}

export enum eErrorMessages {
  APP_INITIALIZATION_FAILED = "An unexpected error occurred. We are working to resolve this.",
  GENERIC_ERROR = 'An unexpected error occurred. Please try again or contact support if the issue persists.',
  API_REQUEST_FAILED = 'Unable to submit the request. Please try again or contact the administrator.',
  API_REQUEST_IN_PROGRESS = 'The request is currently in progress.',
  API_REQUEST_SUCCESS = 'The request was submitted successfully.',
  NO_DATA_FOUND = 'No data found for the selected criteria.'
}

// 'success' | 'warning' | 'info' | 'error' | 'in-progress'
export enum NotificationType {
  Info = 'info',
  InProgress = 'in-progress',
  Error = 'error',
  Success = 'success'
}

export enum eAgGridThemes {
  Alpine = 'ag-theme-alpine',
  Alpine_Dark = 'ag-theme-alpine-dark',
  Alpine_Auto_Dark = 'ag-theme-alpine-auto-dark',
  Quartz = 'ag-theme-quartz',
  Quartz_Dark = 'ag-theme-quartz-dark',
  Quartz_Auto_Dark = 'ag-theme-quartz-auto-dark',
  Balham = 'ag-theme-balham',
  Balham_Dark = 'ag-theme-balham-dark',
  Balham_Auto_Dark = 'ag-theme-balham-auto-dark',
  Material = 'ag-theme-material'
}

export enum SpecialMetricTitles {
  HomePage = 'xPT-HomePage'
}

export const HEADER_TYPES = {
  ACTUAL: 'Actual',
  FORECAST: 'Forecast'
} as const;

export type HeaderType = (typeof HEADER_TYPES)[keyof typeof HEADER_TYPES];

export enum AuditType {
  All = 'All',
  Capex = 'capex_mapping'
}

export enum UserValidationMessages {
  UserIsValid = 'The user is valid.',
  UserIsInactive = 'The user is inactive.',
  UserAliasIsInvalid = 'The user alias is invalid.',
  UserAliasValidationNotInitiated = 'Alias Validation Not Initiated'
}

export enum OperationType {
  CRUD = 'crud',
  FILE_UPLOAD = 'file_upload'
}

export const SELECT_ALL_OPTION = {
  label: 'Select All',
  value: 'Select All'
};
